<template>
  <base-section
    id="statuts"
  >
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="12"
          style="font-size: 19px;"
        >
          <p class="text-h5">
            L'an 2021, le 17 juin,
          </p>
          <p class="text-justify">
            <strong>M. Xavier Niel</strong>, agissant directement et au travers des sociétés qu'il contrôle, A décidé de la constitution d'un fonds de dotation dénommé Fonds pour l'Indépendance de la Presse (le Fonds) régi par la loi de modernisation de l'économie n° 2008-776 du 4 août 2008 et son décret d'application n° 2009-158 du 11 février 2009.
          </p>
          <h1> PRÉAMBULE </h1>
          <p class="text-justify">
            Le Fonds est un organisme d'intérêt général à but non lucratif constitué par le Fondateur en concertation avec M. Matthieu Pigasse et le Pôle d'indépendance du Groupe « Le Monde » (représenté par la société ôle d'indépendance du Groupe Le Monde SPIGM) afin de contribuer à la défense des principes fondamentaux nécessaires à l'existence effective de la liberté d'opinion et d'expression en France, telles que garanties par la Déclaration des droits de l'Homme et du Citoyen de 1789.
          </p>
          <p class="text-justify">
            La liberté de la presse et l'indépendance des journalistes sont des piliers essentiels de tout système démocratique. Ils forment la condition nécessaire d'une information libre et de qualité à laquelle le Fondateur et les deux Partenaires sont attachés de façon absolue.
          </p>
          <p class="text-justify">
            Une démocratie véritable ne saurait s'exercer sans une information libre et pluraliste qui concourt à l'autonomie et au choix des citoyens. Le Fonds a donc pour objectif d'assurer et de protéger cette liberté fondamentale en promouvant un journalisme indépendant des pouvoirs et des groupes de pression, au service du bien commun, des valeurs humanistes et démocratiques.
          </p>
          <p class="text-justify">
            Soucieux de permettre aux journalistes des titres français dans lesquels le Fondateur détient des participations directement ou indirectement de disposer des moyens nécessaires pour exercer rigoureusement leur métier, collecter et vérifier les informations, indépendamment de toute pression extérieure, le Fonds, sous l'impulsion du Fondateur et en concertation avec les deux Partenaires, s'attachera à définir un modèle économique pérenne et à préserver l'indépendance éditoriale des groupes de presse au sein desquels il détient une participation à l'égard de leurs actionnaires, des annonceurs, des pouvoirs publics, politiques, économiques, idéologiques et religieux.
          </p>
          <p class="text-justify">
            Le Fondateurs et les deux Partenaires veilleront, au travers du conseil d'administration du Fonds, au respect de ces principes. Les journalistes sont à ce titre responsables vis-à-vis du public dans leur mission d'information et se doivent de respecter leurs devoirs essentiels, tels qu'exprimés dans la Déclaration des devoirs et des droits de journalistes de Munich (1971), et notamment le respect de la vérité et la défense de la liberté d'information.
          </p>
          <p class="text-justify">
            Ces devoirs ne peuvent toutefois être respectés que si les conditions concrètes de leur indépendance sont garanties, en ce compris une gouvernance respectueuse des droits des journalistes et du caractère essentiel de leur mission.
          </p>
          <p class="text-justify">
            Dans ce cadre, il est envisagé que le Fonds détienne une participation dans certains groupes de presse français afin de sanctuariser le capital de ces groupes et les protéger de l'influence de tiers.
          </p>
          <p class="text-justify">
            Le Fonds sera ainsi notamment amené à détenir une participation dans la société Le Monde Libre, principal actionnaire du groupe « Le Monde ». Cette prise de participation a d'ores et déjà reçu l'aval du Pôle d'Indépendance, et permettra au Fonds de contribuer à la protection de l'indépendance éditoriale des journalistes aux côtés du Pôle d'Indépendance, et dans le respect de la charte d'éthique et de déontologie du Groupe Le Monde. Le Fonds a également, dans le même souci de contribuer à la liberté de la presse et d'expression, vocation à détenir une participation dans les groupes français éditant le journal « L'Obs » et à terme, d'autres participations
          </p>
          <p class="text-justify">
            Outre la détention d'une participation dans certains groupes de presse, le Fonds aura plus largement vocation à exercer une mission d'intérêt général en lien avec la défense de la liberté d'opinion et d'expression en France.
          </p>
          <p class="text-justify">
            Le Fonds portera cette mission d'intérêt général à travers la poursuite de son objet, et en s'attachant à respecter le pluralisme de la presse et l'indépendance des journalistes.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="12"
          style="font-size: 19px;"
        >
          <h1>ARTICLE 1 - CRÉATION ET DÉNOMINATION</h1>
          <p class="text-justify">
            Il est constitué un fonds de dotation régi par la loi de modernisation de l'économie n° 2008-776 du 4 août 2008 et son décret d'application n° 2009-158 du 11 février 2009, tels que complétés et modifiés ultérieurement, et par les présents statuts.
          </p>
          <p class="text-justify">
            Le fonds de dotation est dénommé: Fonds pour l'Indépendance de la Presse
          </p>
        </v-col>
        <v-col
          cols="12"
          md="12"
          style="font-size: 19px;"
        >
          <h1>ARTICLE 2 - OBJET</h1>
          <p class="text-justify">
            Le fonds a pour objet toute action d'intérêt général contribuant à la liberté de la presse et à l'indépendance des journalistes.
            En vue de la réalisation de son objet, le fonds pourra notamment:
          </p>
          <ul>
            <li>
              agir directement ou indirectement, au travers d'organismes à but non lucratif à toute action d'intérêt général en vue du soutien de la presse et de la liberté d'expression;</li>
            <li>
              apporter son soutien financier, à travers l'octroi de subventions, d'avances ou tout autre moyen, à des entreprises de presse ou à toutes actions ou initiatives en faveur de la pluralité de la presse et de l'indépendance des journalistes ;
            </li>
            <li>
              réaliser des prestations de service à but non lucratif au profit d'entreprises de presse ;
            </li>
            <li>
              soutenir, directement ou indirectement, la représentation de la presse et la promotion de son indépendance aussi bien auprès des autorités publiques et de l'administration qu'auprès des acteurs privés et des citoyens ;
            </li>
            <li>
              prendre des participations dans des entreprises de presse aux fins de soutenir leur indépendance et sans but lucratif ;
            </li>
            <li>
              détenir des biens et droits de toute nature et en utiliser les revenus en vue de la réalisation de son objet d'intérêt général; et
            </li>
            <li>
              plus généralement, recevoir toute libéralité, procéder à tout acte d'administration de son patrimoine, entreprendre toute action et exercer toute activité concourant directement ou indirectement à la réalisation de son objet.
            </li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          md="12"
          style="font-size: 19px;"
        >
          <h1>ARTICLE 3 - SIEGE SOCIAL </h1>
          <p class="text-justify">
            Le siège social du Fonds est fixé au 67/69 avenue Pierre Mendès - France, 75013 Paris.
          </p>
          <p class="text-justify">
            Le siège social du Fonds peut être transféré en tous lieux par simple décision du conseil d'administration.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="12"
          style="font-size: 19px;"
        >
          <h1>ARTICLE 4 – DURÉE </h1>
          <p class="text-justify">
            Le Fonds est créé pour une durée indéterminée.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="12"
          style="font-size: 19px;"
        >
          <h1>ARTICLE 5 - ADMINISTRATION ET FONCTIONNEMENT</h1>
          <h4>5.1 - Composition du conseil d'administration </h4>
          <p class="text-justify">
            Le conseil d'administration est composé de 6 membres au moins et 12 membres au plus, personnes physiques ou morales.
          </p>
          <p class="text-justify">
            Il comprend :
          </p>
          <ul>
            <li>deux membres désignés par le Fondateur;</li>
            <li>deux membres désignés par les deux Partenaires(chacun désignant un membre ); et</li>
            <li>deux membres choisis par le Fondateur parmi des personnes qualifiées en tenant compte de leurs compétences et de leur attachement à la liberté de la presse et à son indépendance, après consultation du Pôle d'Indépendance.</li>
          </ul>
          <p class="text-justify">
            Si le nombre de 6 membres composant le conseil d'administration devait être modifié, le Pôle d'Indépendance devra continuer de disposer du droit de désigner 1/6ème des membres (arrondi à l'entier inférieur).
          </p>
          <p class="text-justify">
            Les membres du conseil d'administration devront adhérer aux principes exprimés en préambule des présents statuts et contribueront à garantir l'indépendance économique des titres dans lequel le Fonds détiendra une participation tout en se gardant d'intervenir dans leurs choix éditoriaux et leur traitement de l'information. A ce titre, la qualité de membre du conseil d'administration sera incompatible avec :
          </p>
          <ul>
            <li>un mandat électif national ou local (hors mandats municipaux dans des villes de moins de 30.000 habitants);
            </li>
            <li>des fonctions de direction, d'encadrement ou de représentation d'un parti politique ou d'un syndicat;
            </li>
            <li>des condamnations définitives à des peines de prison ferme liées aux sujets suivants: (i) incitation à la haine raciale ou pour discriminations, (ii) financement illicite de partis politiques, (iii) atteinte aux personnes, (iv) corruption
            </li>
          </ul>
          <p class="text-justify">
            Les premiers membres du conseil d'administration sont:
          </p>
          <ul>
            <li>M. Alain Frachon;</li>
            <li>Mme Nicole Notat;</li>
            <li>Mme Anne Le Lorier;</li>
            <li>M. Olivier Milot;</li>
            <li>M. Jules Niel; et</li>
            <li>Mme Roxanne Varza.</li>
          </ul>
          <br />
          <h4>5.2 - Durée du mandat et révocation des administrateurs</h4>
          <p class="text-justify">
            Les administrateurs sont nommés pour une période initiale de trois (3) ans, et pourront être indéfiniment renouvelés dans leur fonction par la personne l'ayant désigné par périodes de trois (3) ans.
          </p>
          <p class="text-justify">
            Le mandat des administrateurs prend fin avant l'échéance en cas de décès, incapacité ou révocation par la personne l'ayant désigné (i.e. selon le cas le Fondateur ou le Partenaire concerné) après avoir entendu l'administrateur concerné.
          </p>
          <p class="text-justify">
            La révocation ou la fin des fonctions d'un administrateur, avec ou sans justes motifs, n'ouvre droit à aucune indemnité.
          </p>
          <p class="text-justify">
            Dans l'hypothèse où le nombre d'administrateurs serait inférieur à six, le Fondateur ou le Partenaire concerné devra désigner, dans un délai maximal d'un (1) mois, autant de nouveaux administrateurs que nécessaire dans le respect des conditions posées par l'article 5.1 afin que le conseil d'administration soit composé de six administrateurs.
          </p>
          <p class="text-justify">
            Il est précisé que la qualité de « Partenaire » est attachée à M. Matthieu Pigasse à titre intuitu personnae; en conséquence, dans l'hypothèse où M. Matthieu Pigasse ne serait plus en mesure de désigner lui - même un membre du conseil d'administration, ce membre sera désigné par le Fondateur parmi des personnalités qualifiées.
          </p>
          <p class="text-justfiy">
            Le Fondateur et les deux Partenaires se fixent l'objectif d'atteindre la parité Femmes / Hommes dans cette
          </p>
          <h4>5.3 - Rémunération </h4>
          <p class="text-justify">
            Les administrateurs exercent leurs fonctions à titre gratuit. Toutefois, les frais raisonnables qu'ils exposent au titre de leurs fonctions peuvent être remboursés sur justificatifs produits par les intéressés.
          </p>
          <h4>5.4 - Censeurs</h4>
          <p class="text-justify">
            Des censeurs regroupant chacun des groupes de presse dans lequel le Fonds détient une participation seront conviés aux réunions du conseil d'administration comme les administrateurs mais ne disposeront pas du droit de vote(les « Censeurs Presse » >).
          </p>
          <p class="text-justify">
            Pour les besoins du présent article, les sociétés contrôlées, directement ou indirectement, par une société opérationnelle commune seront réputées constituer un seul groupe de presse.
          </p>
          <p class="text-justify">
            Les censeurs sont nommés pour une période initiale de trois(3) ans par la rédaction de chacun des groupes de presse, et pourront être indéfiniment renouvelés dans leur fonction par périodes de trois(3) ans.
          </p>
          <p class="text-justify">
            Le mandat des censeurs prend fin avant l'échéance en cas de décès, incapacité ou révocation par décision du conseil d'administration après avoir entendu le censeur concerné(étant précisé que le censeur concerné pourra assister aux délibérations mais ne prendra pas part au vote).
          </p>
          <p class="text-justify">
            La perte par un Censeur Presse de la qualité de journaliste salarié de la rédaction concernée conformément au présent article 5.4 entraînera la perte immédiate et de plein droit de sa qualité de censeur sans qu'il soit besoin de procéder à une révocation.
          </p>
          <p class="text-justify">La révocation ou la fin des fonctions d'un censeur, avec ou sans justes motifs, n'ouvre droit à aucune indemnité.
          </p>
          <p class="text-justify">En cas de vacance du siège d'un Censeur Presse suite à un cas de décès, incapacité, révocation, démission, non - renouvellement ou tout autre motif, un nouveau censeur devra être désigné comme Censeur Presse conformément aux principes du présent article 5.4. En l'absence d'un tel candidat au siège de Censeur Presse, le conseil d'administration pourra nommer à titre supplétif un journaliste d'une autre rédaction en tant que Censeur Presse jusqu'à ce que des candidats remplissant ces conditions puissent être identifiés. Outre les Censeurs Presse, le conseil d'administration peut nommer d'autres censeurs qui assistent aux délibérations sans droit de vote.
          </p>
          <p class="text-justify">En tout état de cause, le nombre total de Censeurs Presse ne devra jamais excéder le nombre d'administrateurs.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="12"
          style="font-size: 19px;"
        >
          <h4>5.5 Attributions </h4>
          <p class="text-justify">
            Le conseil d'administration règle, par ses délibérations, les affaires du Fonds et notamment :
          </p>
          <ul>
            <li>a) il définit la stratégie générale du fonds ainsi que son programme d'actions d'intérêt général; </li>
            <li>b) il définit et met en œuvre la politique de placement et d'investissement du Fonds;            </li>
            <li>c) il vote le budget et décide de l'allocation des ressources entre les différents projets éligibles; </li>
            <li>d) il approuve les comptes de l'exercice clos qui lui sont présentés;</li>
            <li>e) il accepte les libéralités faites au fonds de dotation, et peut déléguer ce pouvoir au Président dans les limites qu'il détermine ;</li>
            <li>f) il autorise toute acquisition de participations par le Fonds et toute cession ou autre transfert de quelque nature qu'il soit des participations détenues par le Fonds(sous réserve de l'Article 7) ;</li>
            <li>g) il autorise toute autre acquisition ou cession d'actifs non prévue au budget ainsi que tout emprunt ou constitution de sûreté par le Fonds ;</li>
            <li>h) il approuve toute convention entrant dans le champ de l'article L.612-5 du Code de commerce; </li>
            <li>i) il nomme le ou les commissaires aux comptes du Fonds ;</li>
            <li>j) il approuve toute décision de faire appel à la générosité publique dans les conditions prévues au III de l'article 140 de la loi n ° 2008-776 de modernisation de l'économie ;</li>
            <li>k) il approuve toute embauche de salarié par le Fonds et fixe leurs conditions de rémunération ;</li>
            <li>l) il fixe la liste des décisions autres que celles visées dans les présents statuts ne pouvant être prises par le Président sans l'approbation préalable du conseil d'administration ;</li>
            <li>m) il adopte et modifie, le cas échéant, un règlement intérieur ;</li>
            <li>n) i autorise l'ouverture ou la conduite de toute procédure judiciaire, administrative ou arbitrale, et / ou la conclusion de toute transaction, hors celles résultant de la mise en œuvre des polices d'assurances ;</li>
            <li>o) il délibère sur l'affectation du boni de dissolution du Fonds; et</li>
            <li>p) il exerce toute autre prérogative qui lui est conférée par les statuts.</li>
          </ul>
          <br />
          <h4>5.6 Réunions et délibérations</h4>
          <p class="text-justify">Le conseil d'administration se réunit au moins deux fois par an, sur convocation du Président ou à la demande de la moitié de ses membres.
          </p>
          <p class="text-justify">
            La convocation inclut l'ordre du jour et est adressée à chaque administrateur et à chaque censeur quinze(15) jours au moins avant la date prévue, par tous moyens écrits. Par exception, le conseil d'administration peut se réunir sans délai si tous les administrateurs sont présents, en respectant néanmoins un délai minimum de huit(8) jours pour toute décision prévue au f) de l'Article 5.5.
          </p>
          <p class="text-justify">Les décisions du conseil d'administration ne sont régulièrement prises que si la moitié au moins des administrateurs sont présents ou représentés sur première convocation. A défaut, le conseil d'administration sera de nouveau convoqué avec le même ordre du jour et pourra délibérer. Il est précisé que les administrateurs pourront participer à la réunion par tous moyens de télécommunication leur permettant de suivre les débats et d'y prendre part, et seront alors pris en compte pour le calcul du quorum.
          </p>
          <p class="text-justify">Sauf lorsqu'une autre majorité est expressément prévue par les statuts, le conseil d'administration délibère à la majorité simple des administrateurs présents ou représentés. En cas d'égalité des voix, le Président disposera d'une voix prépondérante.
          </p>
          <p class="text-justify">Le Président signe le procès - verbal des séances du conseil d'administration, qui sera communiqué aux administrateurs et aux censeurs.
          </p>
          <p class="text-justify">Les administrateurs et censeurs sont tenus à une obligation de stricte confidentialité couvrant les délibérations du conseil d'administration et les informations relatives au Fonds auxquelles ils ont accès.
          </p>
          <h4>5.7 Président/Directeur général</h4>
          <p class="text-justify">Le Président du conseil d'administration(le « < Président ») est désigné par le Fondateur parmi les membres du conseil d'administration. Le Fondateur pourra décider à tout moment de désigner un autre membre du conseil d'administration en qualité de Président.
          </p>
          <p class="text-justify">Le premier Président est M. Alain Frachon
          </p>
          <p class="text-justify">Le premier Président est M. Alain Frachon
          </p>
          <p class="text-justify">Le Président :
          </p>
          <ul>
            <li>a) assume la gestion courante du Fonds et assure sa représentation dans tous les actes de la vie civile ;
            </li>
            <li>b) dirige et organise les travaux et veille au bon fonctionnement du conseil d'administration ;
            </li>
            <li>c) exécute les décisions du conseil d'administration ;
            </li>
            <li>d) établit le rapport annuel sur la situation morale et financière du Fonds et s'assure du respect par le Fonds de ses obligations administratives et déclaratives
            </li>
            <li>e) exerce tout autre pouvoir qui lui serait délégué par le conseil d'administration.</li>
          </ul>
          <br />
          <p class="text-justify">Le Président du conseil d'administration a la faculté de déléguer tout ou partie de ses attributions à un directeur général qu'il nomme, parmi les administrateurs ou non, et révoque. Il en définit également la rémunération, le cas échéant. Si le directeur général n'est pas administrateur, il pourra assister à l'ensemble des réunions du conseil d'administration sans toutefois disposer d'un droit de vote.</p>
        </v-col>
        <v-col
          cols="12"
          md="12"
          style="font-size: 19px;"
        >
          <h1>ARTICLE 6 - DOTATION INITIALE</h1>
          <p class="text-justify">Le Fonds est constitué avec une dotation initiale en numéraire d'un montant de 15.000 euros, apportée par le Fondateur. Elle peut être complétée par des dotations complémentaires avec l'accord du conseil d'administration, et sera augmentée par les donations, libéralités et legs consentis par des tiers.</p>
          <p class="text-justify">La dotation est apportée au Fonds à titre gratuit et irrévocable </p>
          <p class="text-justify">Le Fonds peut consommer la dotation dans les conditions fixées par le conseil d'administration. La consommation en totalité de la dotation emporte dissolution du Fonds.
          </p>
          <h1>ARTICLE 7- INALIENABILITE</h1>
          <p class="text-justify">Conformément au souhait du Fondateur exprimé dans son acte d'apport, toute participation, présente ou à venir, détenue directement ou indirectement par le Fonds dans les groupes « Le Monde » et « l'Obs » est inaliénable et le Fonds s'interdit en conséquence tout transfert direct ou indirect d'une telle participation, à l'exception(i) de tout transfert à une entité entièrement détenue par le Fonds et(ii) de tout transfert qui serait autorisé par le conseil d'administration se prononçant à l'unanimité de ses membres, en ce compris le vote favorable du représentant du Pôle d'Indépendance.</p>
          <h1>ARTICLE 8 - RESSOURCES </h1>
          <p class="text-justify">Les ressources du Fonds comprennent :
          </p>
          <ul>
            <li>les revenus de sa dotation ;</li>
            <li>les produits des activités prévues aux statuts ;</li>
            <li>les produits d'éventuelles rétributions pour services rendus ;</li>
            <li>les dividendes et autres produits liés à toute participation qu'il détient dans une société ;</li>
            <li>le produit des appels à la générosité publique s'il est autorisé à le faire.</li>
          </ul>
          <p class="text-justify">Le conseil d'administration définit la politique d'investissement du Fonds en vue de faire fructifier les sommes détenues par le Fonds de façon à permettre un financement régulier de sa mission d'intérêt général.</p>
          <h1>ARTICLE 9 - EXERCICE SOCIAL</h1>
          <p class="text-justify">L'exercice social du Fonds court du 1er janvier au 31 décembre de chaque année.</p>
          <p class="text-justify">Par exception, le premier exercice débute à la date de signature des statuts et s'achève le 31 décembre de la même année.</p>
          <h1>ARTICLE 10 - ETABLISSEMENT DES COMPTES</h1>

          <p class="text-justify">Les comptes du fonds de dotation comprennent au moins un bilan, un compte de résultat et, le cas échéant, une annexe. Ils font ressortir la traçabilité des dons effectués.</p>
          <p class="text-justify">Les comptes sont publiés sur le site internet de la Direction de l'information légale et administrative dans les délais légaux.</p>
          <h1>ARTICLE 11 - COMMISSAIRE AUX COMPTES</h1>
          <p class="text-justify">Le commissaire aux comptes est désigné par le conseil d'administration pour une durée de six exercices.</p>
          <p class="text-justify">Les comptes annuels sont mis à sa disposition quinze jours au moins avant la réunion du conseil d'administration à l'approbation duquel ils sont soumis.</p>
          <p class="text-justify">Un ou plusieurs commissaires aux comptes suppléants appelés à remplacer le commissaire aux comptes titulaire en cas de refus, d'empêchement, de démission, de décès ou de relèvement, sont nommés en même temps que ce dernier et pour la même durée.</p>
          <h1>ARTICLE 12 - RAPPORT D'ACTIVITE
          </h1>
          <p class="text-justify">Le Fonds établit chaque année un rapport d'activité qui est soumis à l'approbation du conseil d'administration et qu'il adresse à la préfecture dans les délais légaux. Ce rapport contient les éléments suivants :
          </p>
          <ul>
            <li>une présentation du fonctionnement interne du Fonds ;</li>
            <li>une présentation des rapports du Fonds avec les tiers, comprenant notamment la liste des partenaires du Fonds et des actions de prospection de donateurs menées par le Fonds ;
            </li>
            <li>la liste des actions d'intérêt général financées par le Fonds, avec indication de leurs montants ;</li>
            <li>la liste des personnes morales à but non lucratif bénéficiaires des redistributions du Fonds ;</li>
            <li>si le Fonds a fait appel à la générosité publique, le compte d'emploi des ressources; et</li>
            <li>la liste des libéralités reçues.</li>
          </ul>
          <p class="text-justify">A ce rapport seront joints les comptes annuels et le rapport du commissaire aux comptes.</p>
          <p class="text-justify">Le Fonds devra faire connaître au préfet dans les 3 mois tous les changements survenus dans son administration, notamment ceux relatifs à la liste des dirigeants, à l'adresse du siège social ainsi que les modifications statutaires par l'envoi de :</p>
          <ul>
            <li>la demande de déclaration de modification ou de dissolution; et</li>
            <li>la décision de l'organe délibérant.</li>
          </ul>
          <br />
          <h1>ARTICLE 13- MODIFICATION DES STATUTS</h1>
          <p class="text-justify">Les présents statuts pourront uniquement être modifiés par le Fondateur, étant précisé que toute modification de son objet, de son préambule, ou du droit du Pôle d'Indépendance à désigner un membre(ou plusieurs membres selon le cas) du conseil d'administration conformément à l'Article 5.1 ainsi que toute modification de l'Article 7 nécessitera l'autorisation préalable du conseil d'administration délibérant à la majorité simple des membres présents ou représentés en ce compris le vote favorable du représentant du Pôle d'Indépendance.</p>
          <h1>ARTICLE 14 – AYANTS - DROITS DU FONDATEUR
          </h1>
          <p class="text-justify">En cas de décès du Fondateur, les droits du Fondateur au titre des présents statuts se transmettront de plein droit à ses ayants - droits.</p>
          <h1>ARTICLE 15 - DISSOLUTION
          </h1>
          <p class="text-justify">La dissolution volontaire du Fonds nécessite l'approbation du conseil d'administration à la majorité des trois quarts et l'accord du Fondateur et du représentant du Pôle d'indépendance.
          </p>
          <p class="text-justify">Le conseil d'administration désigne alors un ou plusieurs liquidateurs qu'il charge de procéder à la liquidation des biens du Fonds et à qui ou auxquels il confère tous les pouvoirs nécessaires pour mener à bien cette mission.
          </p>
          <p class="text-justify">Le boni de liquidation éventuel sera dévolu à un ou plusieurs autres fonds de dotation ou fondations reconnues d'utilité publique choisis par le Fondateur.
          </p>
          <p class="text-justify">Fait à Paris, le 17 juin 2021,
          </p>
          <p class="text-justify"><strong>Xavier Niel</strong>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionStatuts',

    data: () => ({
    }),
  }
</script>
