<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(item, i) in items"
        :key="i"
        cols="12"
      >
        <v-card
          :color="item.color"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title
                class="text-h5"
              >
                {{ item.title }}
              </v-card-title>
              <v-card-subtitle> {{ item.desc }}</v-card-subtitle>
              <v-card-actions>
                <div class="text-center">
                  <v-dialog
                    v-model="dialog[i]"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.cta }}
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5">
                        {{ item.title }}
                      </v-card-title>

                      <v-card-text>
                        <p v-html="item.content.content">
                        </p>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          @click="$set(dialog, i, false)"
                        >
                          Fermer
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-card-actions>
            </div>
            <div>
              <v-img
                :src="item.src"
                contain
                max-width="200"
                width="100%"
              />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'SectionActus',

    data: () => ({
      dialog: [],
      items: [
        {
          title: 'Communiqué de presse - 23 avril 2024',
          content: {
            type: 'html',
            content: ` <p>
            le Conseil d’administration du Fonds pour l’indépendance de la presse, réuni le mercredi 17 avril 2024, a voté à l’unanimité l’acquisition, pour la somme de 1 euro, de l’intégralité du capital – moins une action – de NJJ Presse, qui était jusqu’à présent contrôlée par Xavier Niel.
          </p>
          <p>
            À la suite de cette acquisition, le Fonds détient désormais 99,9 % des parts de NJJ Presse, et devient ainsi l’actionnaire de contrôle indirect du Groupe Le Monde, comprenant les sociétés éditrices du journal  <i>Le Monde</i>, de <i>Télérama</i>, <i>Courrier international</i>, <i>La Vie</i>, <i>le HuffPost</i> (85% du capital) et <i>Le Monde diplomatique</i> (51% du capital); ainsi que de la Société éditrice du magazine </>Le Nouvel Obs</i>. Cette cession au Fonds avait préalablement été autorisée par le Pôle d’indépendance le mardi 16 avril, conformément au droit d’agrément dont il dispose en cas de modification de l’actionnariat du Groupe Le Monde.
          </p>
          <p>
            « Ce fonds aura vocation à protéger le capital du Groupe Le Monde. Il sera désormais impossible de le céder », expliquait Xavier Niel peu après l’annonce de sa création. Cette opération sanctuarise de fait l’indépendance capitalistique du Groupe puisque les actions acquises par le Fonds sont statutairement inaliénables.
          </p>
          <p>
            le Fonds pour l’indépendance de la presse est désormais indirectement l’actionnaire majoritaire du Groupe Le Monde au côté du Pôle d’indépendance, principalement composé des sociétés de journalistes et de personnels des différents titres du Groupe (<i>Le Monde</i>, <i>Télérama</i>, <i>Courrier international</i>, <i>La Vie</i>...). À l’avenir, et en cas d’issue favorable du contentieux avec l’héritier de Pierre Bergé, toujours pendant devant la Cour de cassation, le capital du Groupe Le Monde devrait se répartir entre deux actionnaires : le Fonds pour l’indépendance de la presse, qui pourrait en détenir les trois quarts environ, et le Pôle d’indépendance, pour un quart environ.
          </p>
          <p>
            le Fonds pour l’indépendance de la presse n’a pas pour unique vocation de détenir des actions du Groupe Le Monde. Organisme d’intérêt général à but non lucratif, le Fonds s’est fixé plusieurs missions dont les quatre principales sont les suivantes :
          </p>
          <ul>
            <li>
              le cofinancement de frais de justice de médias indépendants, dans le cadre de procédures communément appelées « procédures bâillon » ;
            </li>
            <li>
              le financement ou cofinancement d’enquêtes journalistiques longues contribuant à un débat d’intérêt général, sous forme d’avance remboursable. Ce cofinancement ne peut concerner un média dont le Fonds serait directement ou indirectement actionnaire ;
            </li>
            <li>
              le cofinancement de travaux de recherche scientifique universitaires consacrés à la lutte contre la désinformation et la propagation de fausses nouvelles ;
            </li>
            <li>
              le cofinancement d’initiatives pour une meilleure connaissance du journalisme et de l’information indépendante auprès des jeunes publics.
            </li>
          </ul>`,
          },
           src: '/img/logo-fondIndePresse.133b7800.jpg',
          desc: 'Communiqué de presse du Fonds pour l\'indépendance pour la presse',
          cta: 'Lire le communiqué',
        },
      ],
    }),
  }
</script>
