<template>
  <base-section
    id="statuts"
  >
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="12"
          style="font-size: 19px;"
        >
          <p class="text-h5">
            Editeur
          </p>
          <p class="text-justify">
            FONDS POUR L'INDÉPENDANCE DE LA PRESSE
            <br>
            Fonds de dotation régi par la loi de modernisation de l’économie n° 2008-776 du 4 août 2008
            <br>
            N° SIREN : 920 911 617
            <br>
            Siège social : 67/69, avenue Pierre Mendès-France, 75013 Paris
            <br>

            Directeur de la publication :
            <br>
            Alain FRACHON, Président
          </p>
          <p class="text-justify">
            Nous contacter : <a href="/contact-us"> formulaire de contact</a>
          </p>
          <p class="text-justify">
            Hébergeur :  Tessi - Google Cloud Platform
          </p>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMentionsLegales',

    data: () => ({
    }),
  }
</script>
