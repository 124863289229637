<template>
  <section id="hero">
    <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Appel à projets en cours
        </v-card-title>
        <br>
        <v-card-text>
          <h3>Soutien financier pour une enquête journalistique contribuant à un débat d’intérêt général</h3>
          <br>
          <p style="text-decoration: underline;">
            <strong> Calendrier :</strong>
          </p>
          <p>
            <strong>28 février 2025 :</strong> date limite de dépôt des candidatures
            <br>
            <strong>22 avril 2025 :</strong> annonce publique des projets retenus
          </p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            outlined
            @click="dialog = false"
          >
            Fermer
          </v-btn>

          <v-btn
            color="primary"
            :to="'/post?id=671ab9c73a37ccf54ab6dceb'"
            @click="dialog = false"
          >
            En savoir plus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-img
      :min-height="minHeight"
      max-height="1300px"
      :src="require('@/assets/cover-fondsindeppress.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .9), rgba(5, 11, 31, .9)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading title="FONDS POUR L'INDÉPENDANCE DE LA PRESSE" />

          <base-body>
            La liberté de la presse et l'indépendance des journalistes sont des piliers essentiels de tout système démocratique.
            Ils forment la condition nécessaire d'une information libre et de qualité à laquelle Le Fonds pour l'indépendance de la presse est attaché de façon absolue et qu'il s'emploie à défendre.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn href="/intro">
              En savoir plus
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },
    data: () => ({
      dialog: true,
    }),
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '80vh' : '60vh'

        return `calc(${height} - ${this.$vuetify.application.top - 150}px)`
      },
    },
  }
</script>
