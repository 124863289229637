import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto my-12"},[_c(VImg,{staticClass:"pa-5",attrs:{"height":"150","src":_vm.pic,"aspect-ratio":"1.7"}}),_c(VCardTitle,[_vm._v(_vm._s(_vm.name))]),_c(VCardText,[_c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('span',{staticClass:"me-1"},[_vm._v(_vm._s(_vm.title))])]),_c(VExpansionPanelContent,{staticStyle:{"letter-spacing":"0px"}},[_vm._v(" "+_vm._s(_vm.desc)+" ")]),_c('v-expansion-panel-footer',[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.handleAction(_vm.action)}}},[_vm._v(" "+_vm._s(_vm.action.label || 'cliquer ici')+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }