<template>
  <base-section
    id="post"
    space="56"
  >
    <v-container>
      <v-row>
        <div v-if="!current_post || !current_post.fields || current_post.fields.lenght <= 0">
            
            <v-alert
              border="left"
              colored-border
              elevation="2"
              type="error"
              >
              Pas de formulaire disponible pour ce sujet.
          </v-alert>
         
          </div>
        <v-col v-else>
          <v-alert
          border="top"
          colored-border
          elevation="2"
          v-if="current_post.title"
          >
            <p class="text-h5" style=" font-size: 1.2em !important; letter-spacing: 0">{{ current_post.title }}</p>
            <p class="text-body-2" style=" font-size: 1em !important; " v-html="current_post.description.content"></p>
          </v-alert>
          <v-alert
            color="#ffab00"
            elevation="2"
            colored-border
            border="left"
            type="warning"
            v-if="current_post.eligibility"
          >
            {{ current_post.eligibility }}
          </v-alert>
          <v-alert v-if="current_post.nb"
              border="left"
              colored-border
              elevation="2"
              type="info"
              >
              <div v-if="current_post.nb && current_post.nb.type == 'html'" v-html="current_post.nb.content"></div>
              <p v-else>{{ current_post.nb }}</p>
              
          </v-alert>
          <!-- <p>Vous souhaitez solliciter le soutien du Fonds, remplissez le formulaire ci-dessous: 
          </p> -->
          <v-snackbar
            v-model="snackbar"
            :centered="true"
          >
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="primary"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
        <v-expand-transition>
          <v-form 
            ref="form"
            v-model="valid"
            lazy-validation
            method="post"
            :disabled="disableForm"
            v-show="!formComplete"
          >
          <input type="hidden" :value="current_post._id" name="formid">
          <div v-for="(field, idx) in current_post.fields" :key="idx">
            <div v-if="field.type == 'String'">
              <v-text-field
                v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)"
                v-model="formData[field.name]"
                v-bind="field"
                required
              >
              </v-text-field>
            </div>
            <div v-if="field.type == 'textarea'">
              <v-textarea
                v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)"
                  v-model="formData[field.name]"
                  v-bind="field"
          
        ></v-textarea>
            </div>
          <div v-if="field.type == 'radio'">
            <v-radio-group
              v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)"
              v-model="formData[field.name]"
              :row="field.orientation == 'row'"
              v-bind="field"
            >
              <v-radio
                  v-for="o in field.options"
                  :key="o.name"
                  :label="o.label"
                  :value="o.name"
              ></v-radio>
            </v-radio-group>
          </div>        
          <div v-if="field.type == 'checkbox'">
            <v-checkbox
              v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)"
              v-model="formData[field.name]"
              v-bind="field"
              :required="field.required|| false"
            ></v-checkbox>
          </div>

          <div v-if="field.type == 'file'">
            <div v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)" >
              <!-- <p>Ajouter des documents justificatifs <strong>{{ field['max-files'] ? '(' + field['max-files'] + ' documents maximum )': '' }} </strong> </p> -->
              <p v-if="field.intro"> {{ field.intro }}</p>
              <file-pond
                :disabled="disableForm"
                :init="handleFilePondInit"
                credits=""
                v-bind="field"
                v-model="formData[field.name]"
                :files="formData[field.name]"
                >
            </file-pond>
            </div>
          </div>
           <div v-if="field.type == 'html'">
            <p v-html="field.content"></p>
           </div> 
            
          </div>
          
            <base-btn
              :disabled="!valid || disableForm || (current_post.active == false)"
              color="primary"
              class="mr-4"
              @click="sendFormData"
              v-if="!snackbar"
            >
              Soumettre
            </base-btn>
          </v-form>
        </v-expand-transition>
          
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import axios from "axios";
const FilePond = vueFilePond();
  export default {
    name: 'SectionPost',
    components: {
        FilePond,
      },
    data () {
      return {
        disableForm: false,
        message: '',
        snackbar : false,
        mypost: null,
        valid: false,
        formData: {},
        formComplete: false,
      }
    },
    computed: {
      current_post() {
        console.log('curpost', this.mypost)
        return this.mypost
      },
    },
    methods: {
      sendFormData(){
        if (this.$refs.form.validate()){
         this.disableForm = true
        const {files, ...formData} = this.formData
        const myfiles = []
        this.formData.files.forEach(element => {
          myfiles.push({serverId : element.serverId, type : element.source.type, name: element.source.name, size: element.source.size})
        });
        // formData.files = null
        axios.post(this.current_post.serverPostUrl, {
          data : {
            fields : formData, 
            files : myfiles, 
            customerId: this.current_post.customerId || 'fip', 
            operationId: this.current_post.operationId || 'fip',
            formId: this.current_post._id || 'unknown',
          }})
          .then((response)=>{
            if (response.status >= 200 && response.status <= 300 ){
              this.message = "Votre demande a bien été soumise!"
              this.snackbar = true
              this.disableForm = true
              this.formComplete = true
            }else{
              this.message = "Une erreur est survenue! \n Veuillez réessayer ultérieurement"
              this.snackbar = true
              console.error(response)
              this.disableForm = false
            }
          })
          .catch((err)=> console.log(err))
            this.disableForm = false
            console.log("formData",this.formData)
        }
        
      },
      handleFilePondInit: function () {
      
    },
    },
    watch: {
      formData: {
        handler(newValue, oldValue) {
          // Remarque: `newValue` sera égale à `oldValue` ici
          // à chaque mutation imbriquée tant que l'objet lui-même
          // n'a pas été remplacé.
          // console.log("new Value", newValue)
          // console.log("oldValue ", oldValue)
        },
        deep: true
      }
  },
  async mounted (){
    const response = await axios.get(`${process.env.VUE_APP_FORM_URL}/${this.$route.query.id}`)
        console.log(response)
        if (response && response.status == 200){
          this.mypost = response.data.form
        }else{
          this.mypost = null
        }

        console.log("curr_post", this.mypost)
  }
  }
</script>
