<template>
  <base-section
    id="content"
  >
    <v-container style="padding: 0px;">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="12"
        >
          <div v-html="motpresident"></div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionContent',

    data: () => ({
      motpresident: `
              <p>La presse aux mains des grands entrepreneurs ? Au service de leurs ambitions politiques et de leurs jeux d’influence ? Ballotée au fil  des fusions/acquisitions dans l’édition – le livre, les magazines, les quotidiens - et l’audiovisuel ? On sait cette réalité que toutes les études d’opinion confirment : la presse n’a pas bonne presse. Elle a perdu en crédibilité, trop souvent soupçonnée, à tort ou à raison, d’être au service d’intérêts autres que celui de l’information. Si l’indépendance de la presse a toujours été l’un des piliers de la démocratie, cette loi de la physique politique a rarement été aussi importante qu’aujourd’hui.</p>
              <p>Et c’est pourquoi la cession des parts majoritaires du capital du Groupe Le Monde (qui édite notamment le quotidien Le Monde, Télérama, Courrier international, La Vie, Le Monde diplomatique et le site www.huffingtonpost.fr) et du Nouvel Obs à un Fonds de dotation – le Fonds pour l’indépendance de la presse - est une date dans l’histoire des publications concernées. Ce changement dans la situation capitalistique de ces sociétés marque une volonté de préserver  leur indépendance et de protéger leurs rédactions. Il s’agit de garantir la liberté d’enquêter, de rapporter des faits vérifiés et d’exposer un vaste spectre d’opinions. Il s’agit d’empêcher que les seuls mécanismes du marché ne permettent l’achat d’un titre à l’unique fin de lui imposer une ligne qui ne serait pas naturellement la sienne. </p>
              <p>L’environnement « médiatique » de l’époque est dominé par de puissants réseaux sociaux – source du meilleur comme du pire ; supports d’information et de désinformation ; véhicules de données brutes et théâtre de manipulations diverses. Cette toile de fond bouleverse la vie politique et rend l’art de gouverner plus difficile que jamais. Elle participe de la fragilité des démocraties modernes, elle favorise la diffusion du complotisme, elle est l’outil rêvé des démagogues et elle nourrit la polarisation des opinions. Ici, sur le grisé de nos écrans numériques, rien n’est vérifié, pas plus l’authenticité des images que celle des propos. </p>
              <p>Le remède est connu : une presse, professionnelle,  libre,  qui redonne toute sa force à la réalité factuelle et reconstruit la frontière entre le vrai et le faux. Encore faut-il que ladite presse bénéficie de la confiance du public. Et c’est là, dans cette nécessité d’une crédibilité retrouvée et confortée, que réside l’importance de la création du FIP.</p>
              <p>Elle est advenue en 2021 à l’initiative de Xavier Niel après une tentative de prise de contrôle du groupe par un actionnaire financier. En un mot, Xavier Niel a organisé la cession des parts de capital qu’il détenait à un Fonds de dotation dont la propriété est dès lors incessible, neutralisée, immunisée contre toute offre d’achat ou pacte d’actionnaires. C’est une aventure, à la fois entrepreneuriale et civique, qui l’honore. </p>
              <p>La forme juridique du Fonds de dotation a été créée par la loi dite de modernisation de l’économie de 2008. Le législateur a voulu mettre au point un statut intermédiaire entre la société commerciale – sous ses différentes types – et la fondation traditionnelle. L’ambition est de favoriser le développement d’activités jugées d’intérêt général et qui peuvent trouver avantage à n’être ni gérées dans le cadre d’une société commerciale de droit commun ni dans celui d’une fondation. C’est le visage moderne de la philanthropie adaptée et appliquée aux besoins particuliers de telle ou telle activité agréée comme relevant de l’intérêt général. Certaines entreprises de presse l’ont déjà adopté -  <i>The Guardian </i> en Grande-Bretagne, <i>Libération</i>, <i>Mediapart</i> et, dans une certaine mesure, <i>Ouest France</i> adossé à une association.</p>
              <p>Le FIP peut recevoir des fonds, des biens et des droits divers. Sa propriété est inaliénable, on l’a dit ; les profits réalisés sont réinvestis dans son activité ; le Fonds ne verse pas de dividendes ; ses bilans sont sujets à la certification annuelle d’un commissaire aux comptes. Dans cet ensemble à la propriété ainsi sanctuarisée, chaque société du Groupe conserve sa direction et sa gestion propres. </p>
              <p>Au printemps 2024, après de longues et fastidieuses procédures, la majorité du capital du Groupe Le Monde était dans les mains du FIP. Le reste est détenu par les sociétés d’actionnaires internes.</p>
              <p>Un conseil d’administration exerce sa tutelle sur le bon fonctionnement du FIP. Il est formé de six personnes : Anne Le Lorier, Nicole Notat, Roxanne Varza, Jules Niel, Olivier Milot et Alain Frachon qui en assure la présidence. Olivier Milot, journaliste, représente au Conseil le « Pôle d’indépendance », la structure regroupant les actionnaires « internes » du Groupe « Le Monde » (sociétés de personnel, société de lecteurs et actionnaires fondateurs), qui détient 25 % de son capital ainsi qu’une série de droits et vétos qui garantissent solidement la liberté et l’indépendance des rédactions du groupe. En qualité de représentant du « Pôle d’indépendance », il dispose d’un droit d’agrément sur toute personne ou entité désirant apporter une contribution financière au fonds et sur toute cession de participation (un droit partagé par tous les autres administrateurs). Par ailleurs, les statuts du fonds ne peuvent être modifiés ou sa dissolution prononcée sans l’aval du représentant du pôle d’indépendance. Sylvain Courage, journaliste au Nouvel Obs, titre également contrôlé par le FIP, participe aux travaux du Conseil en qualité de « Censeur », c'est-à-dire sans droit de vote.</p>
              <p>La fonction première de notre assemblée est de s’assurer que les sommes qui lui sont confiées sont bien consacrées à la mission d’intérêt général à but non lucratif du Fonds : défendre et promouvoir l’indépendance des médias.</p> 
              <p> Ainsi, le FIP participe au financement d’enquêtes menées par des journalistes indépendants afin d’être publiées sous forme de livre;
                  il contribue à des travaux de recherche scientifique universitaire destinées à lutter contre la désinformation numérique;
                  il apporte son soutien financier à des journalistes indépendants et poursuivis dans le cadre de leur travail;
                  il accompagne des actions d’éducation à la presse dans les collèges et lycées.
              </p>

              <p>Le Conseil s’engage à rendre compte de ses missions aussi fréquemment que possible sur ce site.</p>
              <p><strong>Alain F. </strong> </p>              
              `,
    }),
  }
</script>
