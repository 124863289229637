<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <base-text-field label="Nom" />

    <base-text-field label="E-mail" />

    <base-text-field label="Sujet" />

    <base-textarea
      class="mb-6"
      label="Une description de votre demande"
    />
    <p v-html="rgpd_mention">

    </p>

    <!-- <v-checkbox
              v-model="rgpd"
              :label="rgpd_mention"
              :required="true"
            ></v-checkbox> -->

    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      outlined
      target="_blank"
      @click="sendMessage"
    >
      Envoyer
    </base-btn>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'ENVOYER VOTRE MESSAGE',
      },
    },
    data()  {
      return {
        rgpd: false,
      }
    },
    methods : {
      sendMessage(){
        // loading and disabling button.
        console.log("sending message")
        // verify data before sending
        const data = {}
        // post the message to server
        axios.post('/contact', data)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
    computed: {
      rgpd_mention(){
        return `En remplissant ce formulaire, vous consentez à ce que le Fonds pour l’Indépendance de la Presse, responsable de traitement, utilise les données personnelles vous concernant pour le traitement de votre demande de contact. Conformément à la loi informatique et libertés du 6 janvier 1978 modifiée et au Règlement européen n°2016/679/UE du 27 avril 2016, vous bénéficiez d’un droit d’accès, de modification, de portabilité, de suppression et d’opposition au traitement des informations vous concernant. Vous pouvez exercer ces droits via ce formulaire de contact. Pour toute information complémentaire ou réclamation, vous pouvez contacter la commission nationale de l’informatique et des libertés (plus d’informations sur www.cnil.fr).`
      }
    }
  }
</script>
